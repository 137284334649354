.main-div{
    width: 80%;

    @include desktop{
        width: 50%;
    }

    .weatherpage-div{
        display: block;
        justify-content: center;
        text-align: center;
        background-color: white;
        border-radius: 5px;
        min-height: 200px;
        margin-top: 20px;
        box-shadow: 0 0 8px darkblue;

        .grey{
            position: fixed;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: rgba(0,0,0,0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            
            .error503-div{
                z-index: 20;
                background-color: white;
                border-radius: 5px;
                width: 80%;
                height: 200px;
                align-items: center;
                margin: auto;
            }
        }
    }
}

.grey-loading{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}
.dailyForecast-div{
    position: relative;

    .arrow-div{
        align-items: center;
        display: flex;
        position: absolute;
    }

    .left{
        top: 50%;
        left: -19px;
    }
    
    .right{
        top: 50%;
        right: -19px;
    }
}

.current-place-weather-info{
    .icon-img{
        width: 100px;
    }

    .city{
        font-size: 21px;
        font-weight: 400;
    }
    .precipitation-div{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        background-color: aliceblue;
        border-radius: 0 0 5px 5px;
        align-items: center;

        .more-info-div{
            display: flex;
            padding: 11px 0;
            
            @include mini-tablet{
                padding: 16px;
            }
            
            @include tablet{
                padding: 20px;
            }

            .precipitation-img{
                width: 42px;
            }

            p{
                padding: 0 0 0 10px;
            }
        }
    }
}