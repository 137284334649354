.search-form-div{
    display:flex;
    justify-content:center;

    form{
        flex:1;
        transform: translateX(28px);
    }

    .icon-img-div{
        z-index: 100;

        .icon-img{
            width: 34px;
            margin-right: 11px;
        }
    }
    .icon-img-div:hover{
        cursor: pointer;
    }
}

.btnStyleFalse{
    height: 31.5px;
    border-radius: 0 10px 10px 0;
    border: 0.5px solid lightgray;
}

.btnStyleTrue{
    background-color: #1776DA;
    color: white;
}

input{
    border: 0.5px solid gray;
    height: 28px;
    border-radius: 10px 0 0 10px;
    outline: none;
    width: 129px;
    
    @include mini-tablet{
        width: 170px;
    }
}