@import "src/scss/imports";

.App {
    background: linear-gradient(0deg, #70bafd 0%, #2885e4 55%, #1776da);
    height: 100vh;
    position: relative;
    margin: auto 0;
    display: flex;
    justify-content: center;
    overflow-x: hidden;

    p{
        font-size: 12px;

        @include mini-tablet{
            font-size: 14px;
        }
    }
    
    .page-div{
        position: relative;
    }
}
