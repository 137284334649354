.sun{ 
    position: absolute;
    top: -15px;
    right: -15px;
    margin: auto;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: #ffffc2;
    box-shadow: 0px 0px 40px 30px #ffffc2; 
    
    @include desktop{
        width: 100px;
        height: 100px;
    }
    
    .ray_box{
        position: absolute;
        top: 0px;
        bottom: 0;
        width: 70px;
        animation: ray_anim 120s linear infinite;

        @include desktop{
            right: 21px;
        }
        
        .ray{  
            margin-left: 10px;
            border-radius: 80% 80% 0 0;
            position: absolute;
            opacity: 0.1;
            background-color: #ffffc2;
            background: linear-gradient(rgb(255,255,0) 0%, rgb(255, 255, 194) 50%, rgba(255, 255, 255, 0.5) 75%, rgba(255,255,255,0.000008) 100%);
        }
        
        .ray1{    
            height:170px;
            width:30px;
            transform: rotate(180deg);
            top: -109px;
            left: 11px;
        }

        .ray2{
            height:100px;
            width:8px;
            transform: rotate(220deg);
            top:-90px;
            left: 75px;
        }

        .ray3{
            height:170px;
            width:28px;
            transform: rotate(250deg);
            top:-80px;
            left: 100px;
        }

        .ray4{
            height:120px;
            width:30px;
            transform: rotate(305deg);
            top:30px;
            left: 100px;
        }

        .ray5{
            height:140px;
            width:30px;
            transform: rotate(-15deg);
            top:60px;
            left: 40px;
        }

        .ray6{
            height: 129px;
            width: 30px;
            transform: rotate(30deg);
            top: 60px;
            left: -40px;
        }

        .ray7{
            height: 178px;
            width: 17px;
            transform: rotate(70deg);
            top: -32px;
            left: -40px;
        }

        .ray8 {
            height:120px;
            width:30px;
            transform: rotate(100deg);
            top:-45px;
            left:-90px;
        }

        .ray9{
            height: 95px;
            width: 10px;
            transform: rotate(120deg);
            top: -65px;
            left: -60px;
        }

        .ray10{
            height: 80px;
            width: 19px;
            transform: rotate(150deg);
            top: -65px;
            left: -17px;
        }
    }
    
    @keyframes ray_anim { 
            0% { transform: rotate(0deg); transform: rotate(0deg);}    
        100% { transform: rotate(360deg); transform: rotate(360deg);}
    }
}
  
  
  