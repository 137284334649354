// Mixins
@mixin phone {
    @media (max-width: 600px) { @content; }    
}
@mixin mini-tablet {
    @media (min-width: 600px) { @content; }    
}
@mixin tablet {
    @media (min-width: 820px) { @content; }    
}
@mixin mini-desktop {
    @media (min-width: 1000px) { @content; }
}
@mixin desktop {
    @media (min-width: 1200px) { @content; }    
}