.loading-data-div{
    min-height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 100000000;
    position: relative;

    .loading-img{
        top: 50%;
        left: 50%;
        width: 270px;
        animation: spinner 10s linear infinite;
        z-index: 100000000;
    }

    .loading-data-p{
        text-align: center;
        
        p{
            color: white;
        }
    }
}

@keyframes spinner {
    to {transform: rotate(360deg);}
}