.dailyForecast-wrapping-div{
    border-radius: 5px;
    margin-top: 20px;
    text-align: center;
    display: flex;
    overflow-x: scroll;
    min-height: 200px;

    @include tablet{
        overflow: hidden;
    }

    .daily-weather-div{
        min-width: 57%;
        min-height: 227px;
        border: gray solid 1px;
        border-radius: 5px;
        background-color: white;
        margin: 10px 8px 10px 4px;
        box-shadow: 0 0 8px darkblue;

        @include mini-tablet{
            min-width: 31%;
        }

        @include tablet{
            min-width: 28%;
        }

        @include desktop{
            min-width: 25%;
        }

        .dayOfTheWeek{
            margin: 14px 0;
            font-size: 14px;
            
            @include tablet{
                font-size: 18px;
            }
        }

        .daily-description{
            text-transform: 'capitalize'
        }

        .icon-img{
            width: 74px;
            padding-top: 20px;
        }

        .precipitation-div{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            background-color: aliceblue;
            border-radius: 0 0 5px 5px;
            margin-top: 24px;

            .more-info-div{
                display: flex;
                justify-content: space-evenly;
                height: 85px;
                border-radius: 0 0 5px 5px;
                align-items: center;
                
                .precipitation-img{
                    width: 35px;

                    @include desktop{
                        width: 33px;
                    }
                }
    
                p{
                    padding: 0 0 0 4px;
                }
            }
        }
    }
}

.dailyForecast-wrapping-div::-webkit-scrollbar { 
    display: none;
}