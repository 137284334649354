.navbar{
    text-align: left;
    margin: 15px;
    a{
        text-decoration: none;
        color: black;
    }
    a:visited{
        color: black;
    }
    a:hover{
        color: black;
    }

    .navbar-div{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
}