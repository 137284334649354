.hourlyForecast-wrapping-div{
    background-color: white;
    border-radius: 5px;
    min-height: 200px;
    margin-top: 20px;
    text-align: center;
        
    .hourlyForecast-div{
        display: flex;
        box-shadow: 0 0 8px darkblue;
        
        .hourly-details{
            min-width: 30%;
            
        }

        table{
            width: 100%;
            
            .table-Head{
                justify-content: space-evenly;
                background-color: aliceblue;

                th{
                    position: sticky; 
                    top: 0;
                    font-size: 14px;
                    background-color: aliceblue;
                    padding: 12px 0;
                    
                    @include tablet{
                        font-size: 15px;
                    }
                    
                    @include desktop{
                        font-size: 17px;
                    }
                }
            }

            td{
                padding: 13px 0;
                border-bottom: solid 0.5px lightgray;
            }

            .icon-img{
                width: 42px;
            }

        }    
        .dayName{
            text-align: left;
            padding-left: 9px;
            font-size: 15px;
            
            @include mini-tablet{
                padding-left: 20px;
            }

            @include tablet{
                padding-left: 32px;
            }

            @include mini-desktop{
                padding-left: 43px;
            }

            @include desktop{
                padding-left: 40px;
            }
        }
    }
    .show-more-btn-div{
        overflow-y: hidden;
        .fas{
            color: lightslategray;
        }
        .show-more-btn{
            background-color: transparent;
            font-size: medium;
            color: lightslategray;
            border: none;
            padding: 9px 0;
        }
        .show-more-btn:hover{
            cursor: pointer;
        }
    }
}